<template>
  <div class="detail-cheers" :class="{skeleton:!state.loaded}" ref="wrapRef">
    <template v-if="state.cheer && state.cheer.rewardCheerList && state.cheer.rewardCheerList.length">
      <ul class="tight">
        <li v-for="(c, idx) in state.cheer.rewardCheerList" :key="idx" :data-depth="c.depth">
          <div class="left">
            <MemberPicture :hide="c.hideInvestorNameYn === 'Y'" :memberSeq="c.memberSeq" size="32" v-if="computedConnectChannel(c.hideInvestorNameYn, c.memberAuth)"/>
            <router-link :to="`/channel/${c.memberSeq}?tabs=projects`" v-else>
              <MemberPicture :memberSeq="c.memberSeq" size="32"/>
            </router-link>
          </div>
          <div class="right">
            <div class="wrapper">
              <div class="info">
                <span class="name font-sm" v-if="computedConnectChannel(c.hideInvestorNameYn, c.memberAuth)">{{ c.hideInvestorNameYn === "Y" ? "키다리 후원자" : "오마이컴퍼니" }}</span>
                <router-link :to="`/channel/${c.memberSeq}?tabs=projects`" class="no-underline" v-else>
                  <span class="name font-sm">{{ c.memberName }}</span>
                </router-link>
                <span class="color-secondary font-xs">{{ c.depth === "0" ? c.regDate : c.createDate }}</span>
              </div>
              <div class="mt-1 font-sm content">
                <span>{{ c.content && c.content.trim() ? c.content : "(내용 없음)" }}</span>
                <div class="action pointer color-secondary font-xs mt-2" @click="editCommentModel(c)" v-if="c.memberSeq && c.memberSeq === $store.state.account.memberSeq">
                  <span class="img align-middle mr-1" style="background-image: url(/assets/ico/common.edit.svg)"></span>
                  <span class="align-middle">수정하기</span>
                </div>
              </div>
            </div>
            <span class="badge badge-point-soft font-xs" v-if="c.totalAmt">{{ $lib.getNumberFormat(c.totalAmt) + computedCurrencyUnit }} 후원</span>
          </div>
        </li>
      </ul>
      <div class="page">
        <Pagination :info="state.pagination" :change="load" v-if="state.pagination"/>
      </div>
    </template>
    <NoData v-else/>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import MemberPicture from "@/components/MemberPicture.vue";
import Pagination from "@/components/Pagination.vue";
import NoData from "@/components/NoData.vue";
import mixin from "@/scripts/mixin";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pageProjectDetailCheers";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MemberPicture, Pagination, NoData},
  props: {
    projectSeq: Number,
    projectType: String,
    investorNameOpenYn: String,
    investorNameAltText: String,
    currencyUnit: String,
  },
  setup(props) {
    const component = new Component(() => {
      for (let i = 0; i < 10; i += 1) {
        state.cheer.rewardCheerList.push({
          depth: "0",
          investorSeq: 0,
          memberAuth: "",
          memberName: "Wait",
          memberSeq: "Wait",
          regDate: "000-00-008 00:00",
          supportComment: "Wait a moment",
          totalAmt: 1000
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      cheer: {
        rewardCheerList: [],
      },
      pagination: null,
    });

    const wrapRef = ref();

    const computedCurrencyUnit = computed(() => {
      return props.currencyUnit || "원";
    });

    const computedConnectChannel = computed(() => {
      return (hideInvestorNameYn, memberAuth) => {
        return hideInvestorNameYn === "Y" || (memberAuth && memberAuth.includes("ADMIN"));
      };
    });

    const load = (num) => {
      state.loaded = false;
      http.get(`/api/reward/projects/${props.projectSeq}/cheer`, {
        pageIndex: num ? num : 1,
        pageUnit: 10,
        pageSize: 5,
      }).then(({data}) => {
        state.loaded = true;
        state.cheer = data.body;
        state.pagination = data.body.paginationInfo;

        if (props.investorNameOpenYn === "N" && state.cheer?.rewardCheerList?.length) {
          for (let i in state.cheer.rewardCheerList) {
            state.cheer.rewardCheerList[i].memberName = props.investorNameAltText || (state.cheer.rewardCheerList[i].memberName.substr(0, 1) + "**");
          }
        }

        // # 976 후원자 이름 변경
        // # 1121 후원자 이름 변경
        if ([15262, 15795].includes(props.projectSeq)) {
          for (let i in state.cheer.rewardCheerList) {
            state.cheer.rewardCheerList[i].memberSeq = null;
            state.cheer.rewardCheerList[i].memberName = state.cheer.rewardCheerList[i].receiveName;
          }
        }
      });

      num && nextTick(() => {
        const rect = wrapRef.value.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        window.scrollTo({
          top: rect.top + scrollTop - 60 - lib.getHeaderHeight(),
          left: 0,
          behavior: "smooth"
        });
      });
    };

    // 후원 시 응원메세지를 따로 작성하지 않은 경우 tb_omc_project_investor_comment 에 값이 없어 수정 안 됨.
    // @Todo 후원 시 무조건 응원 메세지 DB insert
    const putComment = ({investorSeq, content}) => {
      http.put("/api/reward/projects/support-comment", {investorSeq, content}).then(() => {
        store.commit("setSwingMessage", "응원 댓글을 수정하였습니다.");
        load();
      }).catch(httpError());
    };

    const editCommentModel = (comment) => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          investorSeq: comment.investorSeq,
          content: comment.content
        },
        callback: `${component.name}.putComment`,
      });
    };

    return {component, state, wrapRef, computedCurrencyUnit, computedConnectChannel, putComment, editCommentModel, load};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-cheers {
  > ul {
    margin-top: $px32;

    > li {
      background: #fff;
      display: flex;
      gap: $px16;
      align-items: flex-start;

      .left {
        flex-shrink: 0;
      }

      .right {
        flex-grow: 1;
        display: flex;
        gap: $px24;
        justify-content: space-between;
        align-items: flex-start;

        > .wrapper {
          .info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: $px4;
            margin-bottom: $px8;

            > a {
              font-size: 0;
            }

            .name {
              font-weight: 500;
            }
          }

          .content {
            > span {
              white-space: pre-line;
              word-break: keep-all;
            }

            .action {
              > .img {
                width: $px16;
                height: $px16;
                opacity: 0.7;
              }
            }
          }
        }

        .badge {
          padding: $px8 $px16;
          border-radius: $px100;
        }
      }

      &:not(:first-child) {
        padding-top: $px20;
      }

      &[data-depth="1"] {
        position: relative;
        background: $colorBackground;
        border-radius: $px16;
        padding: $px16 $px10 $px16 $px38;
        margin-top: $px8;

        &:before {
          content: " ";
          background-image: url(/assets/ico/component.comment.depth-pipe.svg);
          background-size: 100% 100%;
          width: $px24;
          height: $px24;
          position: absolute;
          top: $px18;
          left: $px10;
        }
      }
    }
  }

  .page {
    margin-top: $px20;
  }

  .no-data {
    margin-top: $px16;
  }

  &.skeleton {
    ul > li {
      .left .img {
        @include skeleton;
      }

      .right {
        > .wrapper {
          .info > span, .info > a {
            @include skeleton;
          }

          .content {
            @include skeleton;

            > .action > span {
              @include skeleton;
            }
          }
        }
      }

      .badge {
        @include skeleton;
      }

      &[data-depth="1"] {
        &:before {
          content: initial;
        }
      }
    }
  }
}
</style>