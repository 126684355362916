<template>
  <div class="detail-ir edit-style stock" :class="{fixed}" ref="wrapRef">
    <div class="row" v-if="state.loaded">
      <div class="article col-lg-9 accordion" :id="`${component.name}DetailIRAccordion`">
        <div class="part condition" v-if="state.tabs.map(t => t.name).includes('issuanceCondition')">
          <div class="parent" :id="`${component.name}IssuanceConditionParent`">
            <button class="btn btn-block" data-toggle="collapse" :data-target="`#${component.name}IssuanceConditionChild`" aria-expanded="true" :aria-controls="`#${component.name}IssuanceConditionChild`">
              <b class="title">증권발행조건</b>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}IssuanceConditionChild`" class="collapse child show" :aria-labelledby="`${component.name}IssuanceConditionParent`">
            <div class="content">
              <div class="main">
                <ul class="tight">
                  <li v-for="(c, idx) in state.issuanceConditions.mains" :key="idx">
                    <div class="wrapper" :class="{'badge-point-soft': idx === 0,'badge-danger-soft': idx === 1,'badge-yellow-soft': idx === 2 }">
                      <div class="font-sm mb-4">
                        <span class="color-black">{{ c.title }}</span>
                      </div>
                      <b class="font-base text-nowrap color-black">{{ c.content }}</b>
                      <span class="font-xs pl-1 text-nowrap color-black" v-if="c.tail">({{ c.tail }})</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="sides" v-if="state.issuanceConditions.sides.length">
                <div class="side" v-for="(items, idx1) in state.issuanceConditions.sides" :key="idx1">
                  <ul class="tight" v-if="items.length">
                    <template v-for="(i, idx2) in items">
                      <li v-if="i.title" :key="idx2">
                        <span class="font-sm color-secondary d-inline-block d-lg-block">{{ i.title }}</span>
                        <b class="font-base font-sans">{{ i.content }}</b>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="part base" v-if="state.tabs.map(t => t.name).includes('info')">
          <div class="parent" :id="`${component.name}InfoParent`">
            <button class="btn btn-block" data-toggle="collapse" :data-target="`#${component.name}InfoChild`" aria-expanded="true" :aria-controls="`#${component.name}InfoChild`">
              <b>기본정보</b>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}InfoChild`" class="collapse child show" :aria-labelledby="`${component.name}InfoParent`">
            <ul class="tight content">
              <li>
                <span class="font-sm color-secondary subject">기업명</span>
                <span class="font-sm">{{ project.corporateName }}</span>
              </li>
              <li>
                <span class="font-sm color-secondary subject">대표명</span>
                <span class="font-sm">{{ project.ceoName }}</span>
              </li>
              <li v-if="project.majorBusinessText">
                <span class="font-sm color-secondary subject">주요사업</span>
                <span class="font-sm">{{ project.majorBusinessText }}</span>
              </li>
              <li v-if="project.staffNumber">
                <span class="font-sm color-secondary subject">임직원수</span>
                <span class="font-sm">{{ project.staffNumber }}</span>
              </li>
              <li v-if="project.corpEstablished">
                <span class="font-sm color-secondary subject">설립 일자</span>
                <span class="font-sm">{{ project.corpEstablished }}</span>
              </li>
              <li v-if="project.homepage">
                <span class="font-sm color-secondary subject">웹 사이트</span>
                <a class="font-sm color-anchor" :href="project.homepage" target="_blank" rel="noopener noreferrer">{{ project.homepage }}</a>
              </li>
              <li>
                <span class="font-sm color-secondary subject">소재지</span>
                <span class="font-sm">{{ project.businessAddr1 }}{{ project.businessAddr2 }}</span>
              </li>
              <li>
                <span class="font-sm color-secondary subject">전화번호</span>
                <span class="font-sm">{{ $lib.getPhoneNumberFormat(project.businessTel, true) }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="part point" v-if="state.tabs.map(t => t.name).includes('point')">
          <div class="parent" :id="`${component.name}PointParent`">
            <button class="btn btn-block" data-toggle="collapse" :data-target="`#${component.name}PointChild`" aria-expanded="true" :aria-controls="`#${component.name}PointChild`">
              <b>투자포인트</b>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}PointChild`" class="collapse child show" :aria-labelledby="`${component.name}PointParent`">
            <ul class="content tight">
              <li class="shadow" v-for="(p, idx) in state.investPoints" :key="idx">
                <span class="font-sm badge badge-point-soft">{{ idx + 1 }}</span>
                <span class="font-sm">{{ p.content }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="part income-deduction" v-if="state.tabs.map(t => t.name).includes('incomeDeduction')">
          <div class="parent" :id="`${component.name}IncomeDeductionParent`">
            <button class="btn btn-block" data-toggle="collapse" :data-target="`#${component.name}IncomeDeductionChild`" aria-expanded="true" :aria-controls="`#${component.name}IncomeDeductionChild`">
              <b>소득공제 혜택</b>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}IncomeDeductionChild`" class="collapse child show" :aria-labelledby="`${component.name}IncomeDeductionParent`">
            <div class="content">
              <ul class="tight">
                <li>
                  <label :for="`${component.name}AnnualIncome`" class="font-sm">연 소득</label>
                  <Number :id="`${component.name}AnnualIncome`" placeholder="과세 표준으로 기입" :value.sync="state.calc.annualIncome" :maxlength="12"/>
                </li>
                <li>
                  <label :for="`${component.name}InvestAmount`" class="font-sm">투자 금액</label>
                  <Number :id="`${component.name}InvestAmount`" placeholder="투자 금액 기입" :value.sync="state.calc.investAmount" :maxlength="12"/>
                </li>
                <li>
                  <label class="font-sm">&nbsp;</label>
                  <button class="btn btn-point btn-block font-sm" @click="calc()">계산</button>
                </li>
              </ul>
              <div>
                <label class="font-base m-0 align-middle">예상 금액</label>
                <b class="font-lg pl-2 align-middle">{{ state.calc.expectedAmount || 0 }}원</b>
              </div>
            </div>
          </div>
        </div>
        <div class="part intro" v-for="(i, idx) in state.investIntro" :key="idx">
          <div class="parent" :id="`${component.name + i.infoSeq}Parent`">
            <button class="btn btn-block" data-toggle="collapse" :data-target="`#${component.name + i.infoSeq}Child`" aria-expanded="true" :aria-controls="`#${component.name + i.infoSeq}Child`">
              <b>{{ i.title }}</b>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name + i.infoSeq}Child`" class="collapse child show" :aria-labelledby="`${component.name + i.infoSeq}Parent`">
            <div class="content" v-html="i.content" :id="`${component.name}Content`"></div>
          </div>
        </div>
        <div class="part reward" v-if="state.tabs.map(t => t.name).includes('reward')">
          <div class="parent" :id="`${component.name}RewardParent`">
            <button class="btn btn-block" data-toggle="collapse" :data-target="`#${component.name}RewardChild`" aria-expanded="true" :aria-controls="`#${component.name}RewardChild`">
              <b>리워드혜택</b>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}RewardChild`" class="collapse child show" :aria-labelledby="`${component.name}RewardParent`">
            <div class="content">
              <ul class="tight">
                <li v-for="(r, idx) in state.rewards" :key="idx">
                  <div class="left">
                    <span class="img" style="background-image: url(/assets/ico/common.smile.point.svg)"></span>
                    <b class="color-point">{{ r.investorCnt }}명 참여</b>
                  </div>
                  <div class="right">
                    <div class="badges">
                      <span class="badge badge-secondary-soft font-xs">{{ $lib.getNumberFormat(r.rewardStartAmt) }}원 이상</span>
                      <span class="badge badge-secondary-soft font-xs" v-if="Number(r.rewardQty) === 0">무조건 제공</span>
                      <span class="badge badge-secondary-soft font-xs" v-else-if="r.rewardQty <= r.investorCnt">0개 남음</span>
                      <span class="badge badge-secondary-soft font-xs" v-else>{{ r.rewardQty - r.investorCnt }}개 남음</span>
                    </div>
                    <b class="font-lg bold">{{ r.title }}</b>
                    <span class="font-sm color-secondary">{{ r.detailText }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="part corp" v-if="state.tabs.map(t => t.name).includes('corp')">
          <div class="parent" :id="`${component.name}CorpParent`">
            <button class="btn btn-block" data-toggle="collapse" :data-target="`#${component.name}CorpChild`" aria-expanded="true" :aria-controls="`#${component.name}CorpChild`">
              <b>기업 소개</b>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}CorpChild`" class="collapse child show" :aria-labelledby="`${component.name}CorpParent`">
            <div class="content">
              <div class="history">
                <div class="title font-sm">
                  <span class="img" style="background-image: url(/assets/ico/common.check.svg)"></span>
                  <span>회사 연혁</span>
                </div>
                <ul class="tight">
                  <li v-for="(h, idx) in state.companyHistory" :key="idx">
                    <div class="mb-3">
                      <b>{{ h.title }}</b>
                      <b class="pl-1">{{ h.subText }}</b>
                    </div>
                    <span>{{ h.content }}</span>
                  </li>
                </ul>
              </div>
              <div class="news" v-if="state.companyNews && state.companyNews.contentList.length">
                <div class="title font-sm">
                  <span class="img" style="background-image: url(/assets/ico/common.check.svg)"></span>
                  <span>관련 뉴스</span>
                </div>
                <div class="list">
                  <a class="shadow no-underline" :href="n.url" target="_blank" rel="noopener noreferrer" v-for="(n, idx) in state.companyNews.contentList" :key="idx">
                    <div class="subject">
                      <b>{{ n.title }}</b>
                      <span class="color-secondary font-xs d-block mt-2">{{ n.subText }}</span>
                    </div>
                    <i class="fa fa-angle-right color-secondary"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="part file" v-if="state.tabs.map(t => t.name).includes('file')">
          <div class="parent" :id="`${component.name}FileParent`">
            <button class="btn btn-block" data-toggle="collapse" :data-target="`#${component.name}FileChild`" aria-expanded="true" :aria-controls="`#${component.name}FileChild`">
              <span class="left">
                <b>첨부자료</b>
                <a class="download-all btn btn-point-soft font-sm" @click.stop="downloadAll()">
                  <span class="align-middle color-point">전체 다운로드</span>
                  <span class="img ml-1 align-middle" style="background-image: url(/assets/ico/page.project.detail-investor-relations.download.point.svg)"></span>
                </a>
              </span>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}FileChild`" class="collapse child show" :aria-labelledby="`${component.name}FileParent`">
            <div class="content">
              <div class="title font-sm mb-2">
                <span class="img align-middle mr-1" style="background-image: url(/assets/ico/common.check.svg)"></span>
                <span class="align-middle">필수자료</span>
              </div>
              <ul class="tight">
                <li v-for="(f, idx) in state.files.requires" :key="idx">
                  <span>{{ f.fileTitle }}</span>
                  <div class="actions">
                    <a class="font-sm btn btn-light" v-if="f.fileOrigName && ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf'].includes(getFileExtension(f.fileOrigName).toLowerCase())"
                       @click="previewFile(f.fileSeq)"
                       target="_blank" title="클릭하여 미리보기">
                      <span class="img" style="background-image: url(/assets/ico/page.project.detail-investor-relations.preview.svg)"></span>
                    </a>
                    <a class="font-sm btn btn-light" @click="download(f.fileSeq)" title="클릭하여 다운로드">
                      <span class="img" style="background-image: url(/assets/ico/page.project.detail-investor-relations.download.svg)"></span>
                    </a>
                  </div>
                </li>
              </ul>
              <div class="title font-sm mb-2 mt-5">
                <span class="img align-middle mr-1" style="background-image: url(/assets/ico/common.check.svg)"></span>
                <span class="align-middle">참고자료</span>
              </div>
              <ul class="tight">
                <li v-for="(f, idx) in state.files.references" :key="idx">
                  <span>{{ f.fileTitle }}</span>
                  <div class="actions">
                    <a class="font-sm btn btn-light" v-if="f.fileOrigName && ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf'].includes(getFileExtension(f.fileOrigName).toLowerCase())"
                       @click="previewFile(f.fileSeq)"
                       target="_blank" title="클릭하여 미리보기">
                      <span class="img" style="background-image: url(/assets/ico/page.project.detail-investor-relations.preview.svg)"></span>
                    </a>
                    <a class="font-sm btn btn-light" @click="download(f.fileSeq)" title="클릭하여 다운로드">
                      <span class="img" style="background-image: url(/assets/ico/page.project.detail-investor-relations.download.svg)"></span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="part file" v-if="state.tabs.map(t => t.name).includes('closingData')">
          <div class="parent" :id="`${component.name}ClosingDataParent`">
            <button class="btn btn-block" data-toggle="collapse" :data-target="`#${component.name}ClosingDataChild`" aria-expanded="true" :aria-controls="`#${component.name}ClosingDataChild`">
              <b>공시자료</b>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}ClosingDataChild`" class="collapse child show" :aria-labelledby="`${component.name}ClosingDataParent`">
            <div class="content">
              <ul class="tight">
                <li v-for="(f1, idx1) in state.files.closingDataList" :key="idx1">
                  <span>{{ idx1 + "년" }}</span>
                  <div class="actions">
                    <a class="font-sm btn btn-light" v-if="f1.files[0].fileOrigName && ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf'].includes(getFileExtension(f1.files[0].fileOrigName).toLowerCase())"
                       @click="previewFile(f1.files[0].fileSeq)"
                       target="_blank" title="클릭하여 미리보기">
                      <span class="img" style="background-image: url(/assets/ico/page.project.detail-investor-relations.preview.svg)"></span>
                    </a>
                    <a class="font-sm btn btn-light" @click="download(f1.files[0].fileSeq)" title="클릭하여 다운로드">
                      <span class="img" style="background-image: url(/assets/ico/page.project.detail-investor-relations.download.svg)"></span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="part warning" v-if="state.tabs.map(t => t.name).includes('warn')">
          <div class="parent" :id="`${component.name}WarnParent`">
            <button class="btn btn-block" data-toggle="collapse" :data-target="`#${component.name}WarnChild`" aria-expanded="true" :aria-controls="`#${component.name}WarnChild`">
              <b>투자 위험 고지</b>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}WarnChild`" class="collapse child show" :aria-labelledby="`${component.name}WarnParent`">
            <div class="content font-sm">
              <div class="summary">
                본 투자위험고지서는 [자본시장과 금융투자업에 관한 법률(이하
                "자본시장법")] 제117조의7 제4항에 의거하여 주식회사
                오마이컴퍼니(이하 ‘(주)오마이컴퍼니’)가 인터넷 홈페이지를 통하여
                온라인소액투자중개하는 금융투자상품에 대한 투자에 따르는 위험을
                귀하가 사전에 충분히 인지할 수 있도록 귀하에게 교부하는 것입니다.
              </div>
              <div>
                1. 귀하가 청약하고자 하는 금융투자상품은 자본시장법에 따른
                "증권"에 해당하므로 원본손실의 위험성이 있습니다. 또한 발행인이
                제시한 예상수익이나, 귀하가 기대하는 수익의 일부 또는 전부를 얻지
                못할 수 있습니다.
                <br/><br/>
                2. 귀하는 (주)오마이컴퍼니의 홈페이지에 게재(정정)된 모집되는
                증권의 발행조건, 발행인의 재무상태가 기재된 서류 및 사업계획서의
                내용, 증권의 취득에 따른 투자위험요소 등 모집게재사항을 충분히
                확인하고, 청약의 주문 시 (주)오마이컴퍼니가 별도로 정한 방법에
                의하여 통지한 투자 위험 등의 내용에 대하여 주의 깊게 확인하여야
                합니다.
                <br/><br/>
                3. (주)오마이컴퍼니는 자본시장법 제117조의11에 따라, 투자자 보호를
                위하여 투자 중개에 앞서 발행인 및 그 증권에 관한 주요 정보의 사실
                여부를 확인합니다.
                <br/><br/>
                다만, 게재된 투자모집정보에 관한 사항은 게재정보의 최신성 유지를
                위해 수정 혹은 삭제 및 추가될 수 있습니다. 변경사항에 대해서는
                당사의 홈페이지에 공지하며, 청약기간의 말일로부터 7일 이내에 수정
                혹은 삭제 및 추가사항이 있을 경우에는 투자자가 변경된 정보를
                토대로 충분한 의사판단을 할 수 있도록 해당일로부터 7일 후로
                청약기간을 연장합니다. 따라서 투자자는 자신이 이미 청약을
                완료하였다고 하더라도 모집이 종료되기 전까지는 당사의 홈페이지를
                통해 모집정보에 대한 지속적인 확인을 해야 합니다.
                <br/><br/>
                4. 모집성공 후 "예탁"되어지는 증권의 경우, 귀하의 증권계좌번호와
                계좌유효성이 반드시 확인되어야 합니다. 증권계좌번호와 계좌유효성이
                확인되어지지 않은 청약자는 모집성공 이후라도 청약이 취소될 수
                있습니다. 투자 완료 후 홈페이지의 ‘마이페이지 - 참여프로젝트 -
                증권형’ 페이지로 가면 자신의 투자 내역을 확인할 수 있으며,
                증권계좌 정보의 수정이 가능합니다.
                <br/><br/>
                5. 청약의 철회(취소)는 청약기간 중에만 가능(청약기간 종료일
                이후에는 청약의 철회 불가)하며, 청약기간의 종료 시 모집금액이
                발행인이 목표한 모집예정금액의 80%에 미달하는 경우 모집실패로
                간주하여 증권의 발행은 취소됩니다. 청약의 철회(취소) 시
                청약증거금은 귀하의 투자예치금계좌에 복원됩니다.
                <br/><br/>
                6. 모집의 성공 후 발행되는 모든 증권은 비상장 증권이므로
                한국거래소 상장을 목적으로 하는 것이 아니며, 증권의 환금성에 큰
                제약이 있을 수 있습니다. 귀하는 이로 인해 투자금액에 대한 일부
                또는 전부를 회수할 수 없는 위험이 있음을 이해하여야 합니다.
                <br/><br/>
                7. 귀하는 크라우드펀딩을 통해 발행된 증권이 자본시장법 제117조의10
                제7항에 따라, 전문투자자에 대한 매도 등 예외적인 경우를 제외하고는
                원칙적으로 6개월간 전매가 제한된다는 점을 이해하여야 합니다.
                <br/><br/>
                8. 귀하는 아래의 사항에 대해서도 내용을 확인하여야 합니다.
                <br/><br/>
                - (주)오마이컴퍼니는 온라인소액증권 청약과 관련하여 투자자로부터
                별도의 수수료를 징수하지 않습니다. 다만 청약증거금 용도의 자금을
                투자예치금계좌에 이체할 때 이용하는 은행의 정책에 따라 타행이체의
                경우 이체수수료가 발생할 수 있습니다.
                <br/><br/>
                - 발행인이 증권의 발행조건과 관련하여 조기상환조건을 설정하거나,
                전환조건을 설정한 경우에는 이에 대한 구체적인 내용을
                (주)오마이컴퍼니 홈페이지를 통해 반드시 확인하여야 합니다.
                <br/><br/>
                - (주)오마이컴퍼니는 자본시장법상에 따른
                "온라인소액투자중개업자"이며, 온라인소액증권발행인과
                온라인소액투자중개계약을 체결하여 위 발행인이 발행하는 증권에 대한
                청약거래를 중개하는 역할만 수행하며, 직접 증권을 발행하거나 주금을
                납입받지 않습니다.
                <br/><br/>
                - (주)오마이컴퍼니는 크라우드펀딩을 통한 자금모집을 중개하는
                온라인소액투자중개업자로서 거래의 당사자가 아니며, 투자로 인한
                손실을 보전하지 않습니다.
                <br/><br/>
                - 청약의 우대 차별 사유 : (주)오마이컴퍼니는 발행인의 요청에 따라
                법적으로 설정가능한 범위 내에서 합리적이고 명확한 기준(선착순,
                청약금액순, 전문투자자순 등)에 따라 투자자의 자격 등을 제한할 수
                있습니다. 이 경우 귀하는 위 기준과 조건에 따라 청약거래에 있어
                차별을 받게 될 수 있습니다.
                <br/><br/>
                9. 정부의 방침에 따라 크라우드펀딩 제도의 변경이 있을 수 있습니다.
                귀하는 자본시장법 등 관련법규에 근거하여 투자자의 권리에 대해 제한
                및 변경이 있는 경우 이를 준수하여야 합니다.
                <br/><br/>
                위 사항들은 (주)오마이컴퍼니를 통한 온라인소액증권 청약의
                제도∙위험 및 청약의 주문 거래와 관련하여 귀하가 알아야 할 사항을
                간략하게 서술한 것으로 거래와 관련하여 발생될 수 있는 모든 위험과
                중요 사항을 전부 기술한 것은 아닙니다. 따라서 상세한 내용은 (주)
                오마이컴퍼니 및 자본시장법상의 관계법규를 통하여 확인하시기
                바랍니다.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ctrl col-lg-3 d-none d-lg-block" ref="ctrlRef">
        <div :id="`${component.name}CtrlWrapper`" class="wrapper">
          <ProjectDetailJoinBtn
              :class="projectType === 'invest' && 'invest'"
              :loaded="state.loaded"
              :projectType="projectType"
              :progressOrder="Number(project.progressOrder)"
              :submit="submit"
              :successFailFlag="project.successFailFlag"
              :subscribeRate="project.subscribeRate"
              :endFlag="project.endFlag"
              :displayStateName="project.displayStateName"
              :subscribeAmount="Number(project.subscribeAmount)"
              :totAmount="Number(project.totAmount)"
              :investSubmit="investSubmit"
              v-if="project.endFlag !== 'Y' && fixed"/>
          <MakerInfo
              :invest="true"
              :builderSeq="project.openId || project.memberSeq || project.builderSeq"
              :projectType="projectType"
          />
          <ul class="tight thin-scrollbar">
            <li :class="{active:t.active}" v-for="(t, idx) in state.tabs" :key="idx">
              <button class="btn font-sm" :class="{'btn-light border-point': t.active, 'btn-light': !t.active}" @click="move(idx)">
                <span class="title" :class="{'color-point':t.active}">{{ t.title }}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Number from "@/components/Number";
import MakerInfo from "@/components/MakerInfo";
import ProjectDetailJoinBtn from "@/components/ProjectDetailJoinBtn.vue";

function Component(initialize) {
  this.name = "pageProjectInvestorRelation";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {ProjectDetailJoinBtn, MakerInfo, Number},
  props: {
    project: Object,
    projectSeq: Number,
    projectType: String,
    investSubmit: Function,
    submit: Function,
    fixed: Boolean,
  },
  setup(props) {
    const component = new Component(() => {
      state.loaded = false;

      http.get(`/api/${props.projectType}/projects/${props.projectSeq}/intro`).then(({data}) => {
        state.loaded = true;
        state.investIntro = data.body.investIntro;
        state.rewards = data.body.rewardList;
        state.companyHistory = data.body.companyHistory || [];
        state.companyNews = data.body.companyNews;
        state.issuanceConditions.mains = [];
        state.issuanceConditions.sides = [[], [], []];

        state.investIntro && nextTick(() => {
          store.commit("setImagePopupListener", {
            $parents: document.querySelectorAll(`#${component.name}Content`),
            url: `/invest/${props.projectSeq}`,
            title: props.project.projectName
          });
        });

        if (data.body.issuanceCondition) {
          const conditions = data.body.issuanceCondition;

          for (let i = 0; i < conditions.length; i += 1) {
            if (i < 3) {
              if (i === 1) {
                if (["R", "Y"].includes(props.project.increaseStockCountDisplayType)) {
                  const texts = [
                    lib.getNumberFormat(lib.getNumbered(props.project.totAmount, true) + lib.getNumbered(props.project.increaseStockCount, true) * lib.getNumbered(props.project.stockAmount)),
                    "원 증액 ",
                    props.project.increaseStockCountDisplayType === "R" ? "예정" : "완료",
                  ];

                  conditions[i].tail = texts.join("");
                }
              }

              state.issuanceConditions.mains.push(conditions[i]);
            } else {
              state.issuanceConditions.sides[i % 3].push(conditions[i]);

              // for (let j = 0; j < state.issuanceConditions.sides.length; j += 1) {
              //   if (state.issuanceConditions.sides[j].length < 4) {
              //     state.issuanceConditions.sides[j].push(conditions[i]);
              //     break;
              //   }
              // }
            }
          }
        }

        state.issuanceConditions.mains.length
        && state.tabs.push({name: "issuanceCondition", title: "증권발행조건", active: false});

        props.project
        && state.tabs.push({name: "info", title: "기본정보", active: false});

        if (props.project.investPoint) {
          state.investPoints = JSON.parse(props.project.investPoint);
          state.tabs.push({name: "point", title: "투자포인트", active: false});
        }

        if (props.project.incomeDeductionFlag === "Y" && props.project.incomeDeductionCalcYn === "Y") {
          state.tabs.push({name: "incomeDeduction", title: "소득공제 혜택", active: false});
        }

        for (let i of state.investIntro) {
          state.tabs.push({name: "investIntro" + i, title: i.title, active: false});
        }

        if (props.project.rewardFlag === "Y" && Array.isArray(state.rewards) && state.rewards.length) {
          state.tabs.push({name: "reward", title: "리워드혜택", active: false});
        }

        if (state.companyHistory.length) {
          state.tabs.push({name: "corp", title: "기업소개", active: false});
        }

        state.tabs.push({
          name: "file", title: "첨부자료", active: false
        });

        state.tabs[0].active = true;

        for (let name of ["investNotiFile", "certFile", "copyFile", "articleFile", "financeFile"]) {
          Array.isArray(data.body[name]) && data.body[name].length
          && state.files.requires.push(data.body[name][0]);
        }

        state.files.references = data.body.etcFileList;
        state.files.closingDataList = data.body.closingDataList;

        Object.keys(state.files.closingDataList).length && state.tabs.push({
          name: "closingData", title: "공시자료", active: false
        });

        state.tabs.push({
          name: "warn", title: "투자 위험 고지", active: false
        });

        if (props.project && props.project.homepage && !props.project.homepage.startsWith("http")) {
          props.project.homepage = "http://" + props.project.homepage;
        }
      });
    });

    const state = reactive({
      loaded: false,
      investIntro: null,
      rewards: [],
      issuanceConditions: {
        mains: [],
        sides: [[], [], []]
      },
      investPoints: [],
      companyHistory: [],
      companyNews: [],
      tabs: [],
      calc: {
        annualIncome: 0,
        investAmount: 0,
        expectedAmount: "",
      },
      files: {
        requires: [],
        references: [],
        closingDataList: [],
      },
      isDownloading: false,
    });

    const wrapRef = ref();
    const ctrlRef = ref();

    const calc = () => {
      const annualIncome = state.calc.annualIncome;
      const investAmount = state.calc.investAmount;
      let result = 0;
      state.calc.expectedAmount = "";

      if (!annualIncome) {
        document.getElementById(`${component.name}AnnualIncome`).focus();
        return store.commit("setSwingMessage", "연 소득을 입력해주세요.");
      } else if (!investAmount) {
        document.getElementById(`${component.name}InvestAmount`).focus();
        return store.commit("setSwingMessage", "투자 금액을 입력해주세요.");
      } else if (annualIncome < investAmount) {
        document.getElementById(`${component.name}InvestAmount`).focus();
        return store.commit("setSwingMessage", "투자 금액이 연소득보다 낮아야 합니다.");
      }

      if (annualIncome > 50000000) {
        result = (annualIncome * 0.3) - (annualIncome - investAmount) * 0.3;
      } else if (annualIncome > 30000000 && annualIncome <= 50000000) {
        result = (annualIncome * 0.7) - (annualIncome - investAmount) * 0.7;
      } else if (annualIncome <= 30000000) {
        result = annualIncome - (annualIncome - investAmount);
      }

      state.calc.expectedAmount = lib.getNumberFormat(result);
    };

    const move = (idx) => {
      const part = wrapRef.value.querySelectorAll(".article .part")[idx];
      const rect = part.getBoundingClientRect();
      const contentOffsetTop = rect.top;
      const curScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      window.scrollTo({
        top: contentOffsetTop + curScrollTop,
        left: 0,
        behavior: "smooth"
      });
    };

    const getFileExtension = (filename) => {
      const lastDotIndex = filename.lastIndexOf(".");
      return lastDotIndex === -1 ? "" : filename.slice(lastDotIndex + 1);
    };

    const downloadAll = () => {
      if (!store.state.account.loggedIn) {
        // return store.commit("openModal", {
        //   name: "Login",
        // });

        return store.dispatch("goLoginPage");
      }

      if (state.isDownloading) {
        return store.commit("setSwingMessage", "다운로드가 이미 진행 중입니다. 잠시 기다려주세요.");
      }

      state.isDownloading = true;
      location.href = `/v2/api/invest/projects/${props.projectSeq}/file/download-all`;
      setTimeout(() => {
        state.isDownloading = false;
      }, 1500);
    };

    const previewFile = (fileSeq) => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      window.open(`/v2/api/invest/projects/${props.projectSeq}/file/${fileSeq}/preview`, "_blank");
    };

    const download = (fileSeq) => {
      if (!store.state.account.loggedIn) {
        // return store.commit("openModal", {
        //   name: "Login",
        // });

        return store.dispatch("goLoginPage");
      }

      if (state.isDownloading) {
        return store.commit("setSwingMessage", "다운로드가 이미 진행 중입니다. 잠시 기다려주세요.");
      }

      state.isDownloading = true;
      location.href = `/v2/api/invest/projects/${props.projectSeq}/file/${fileSeq}/download`;
      setTimeout(() => {
        state.isDownloading = false;
      }, 1500);
    };

    const onScroll = () => {
      const parts = wrapRef.value.querySelectorAll(".article .part");
      const scrollY = window.pageYOffset;
      const fixedBarHeight = document.getElementById("pageProjectDetailTabs")?.clientHeight || 0;

      for (let i in parts) {
        if (parts[i] && typeof parts[i].getBoundingClientRect === "function") {
          const rect = parts[i].getBoundingClientRect();
          const start = rect.top + scrollY - fixedBarHeight - 1;
          const end = start + rect.height;

          if (scrollY > start && scrollY < end) {
            for (let j in state.tabs) {
              state.tabs[j].active = i === j;
            }
            break;
          }
        }
      }

      // if (window.innerWidth > 991) {
      //   const $elem = document.getElementById(`${component.name}CtrlWrapper`);
      //
      //   if (!$elem) {
      //     return;
      //   }
      //
      //   const ctrlWrapperMaxHeight = window.innerHeight - lib.getHeaderHeight() - 64;
      //   $elem.style.maxHeight = ctrlWrapperMaxHeight + "px";
      // }
    };

    // const click = (e) => {
    //   if (!e.target.attributes.src || !lib.isMobile()) {
    //     return;
    //   }
    //
    //   const $contentWrap = document.querySelectorAll(`#${component.name}Content`);
    //   const $imgs = [];
    //   const paths = [];
    //
    //   for (let i = 0; i < $contentWrap?.length; i += 1) {
    //     $imgs.push(...$contentWrap[i]?.querySelectorAll("img"));
    //   }
    //
    //   for (let i = 0; i < $imgs.length; i += 1) {
    //     paths.push(i === 0 ? `?image${i}=${$imgs[i]?.attributes?.src?.value}` : `&image${i}=${$imgs[i]?.attributes?.src?.value}`);
    //   }
    //
    //   window.open(`/popup/slides${paths.join(" ")}`, "", "noopener,noreferrer");
    // };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    onUnmounted(() => {
      store.commit("setImagePopupListener", {
        $parents: document.querySelectorAll(`#${component.name}Content`),
        destroy: true
      });
    });

    return {component, state, wrapRef, ctrlRef, calc, move, getFileExtension, downloadAll, download, previewFile};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-ir {
  position: relative;

  ul {
    padding-left: 0;
    margin: 0;
  }

  .btn {
    transition: background-color 0.18s, border-color 0.18s, color 0.18s;
  }

  > .row > .article {
    .part {
      .parent {
        > .btn {
          padding: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          b {
            font-size: $px20;
            font-weight: 600;
          }

          &.collapsed {
            i {
              transform: rotate(180deg);
            }
          }
        }
      }

      .child {
        > div, > ul {
          padding-top: $px16;
        }

        > .content {
          max-width: 812px;
        }
      }

      &.condition {
        .child .content {
          .main {
            margin: 0 -15px;

            > ul {
              padding: 0 15px;
              overflow: auto;
              white-space: nowrap;

              li {
                display: inline-block;
                min-width: $px160;

                > .wrapper {
                  padding: $px20;
                  border-radius: $px16;
                }

                &:not(:last-child) {
                  margin-right: $px8;
                }
              }
            }
          }

          .sides {
            display: flex;
            margin-top: $px16;

            .side {
              width: calc(100% / 3);

              ul {
                li {
                  padding: $px8 0;

                  .font-sans {
                    font-family: Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
                  }
                }
              }
            }
          }
        }
      }

      &.base {
        .child ul.content {
          li {
            display: flex;
            gap: $px8;

            span {
              font-weight: 400;

              &.subject {
                width: $px80;
                flex-shrink: 0;
              }
            }

            &:not(:last-child) {
              margin-bottom: $px8;
            }
          }
        }
      }

      &.point {
        .child ul.content {
          li {
            border-radius: $px24;
            display: flex;
            align-items: center;
            gap: $px16;
            padding: $px16;

            .badge {
              border-radius: $px10;
              flex-shrink: 0;
              padding: $px8;
              width: $px32;
              height: $px32;
            }

            &:not(:last-child) {
              margin-bottom: $px10;
            }
          }
        }
      }

      &.income-deduction {
        .child .content {
          font-weight: 400;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          gap: $px24;

          ul {
            display: flex;
            gap: $px8;
            align-items: center;

            li {
              .form-control {
                border-radius: $px16;
                height: $formHeightLg;
              }

              .btn {
                border-radius: $px16;
                height: $formHeightLg;
                white-space: nowrap;
              }
            }
          }

          > div {
            padding-bottom: $px8;
          }
        }
      }

      &.intro::v-deep {
        img, iframe {
          width: auto;
          height: auto;
        }
      }

      &.reward {
        .child .content {
          ul li {
            display: flex;
            overflow: hidden;
            width: 100%;

            .left {
              background-color: $colorPointSoft;
              border: $px1 solid #f2f2f2;
              border-right: 0;
              border-radius: $px24 0 0 $px24;
              flex-basis: 20%;
              flex-shrink: 0;
              padding: $px20;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: $px8;

              > .img {
                width: $px32;
                height: $px32;
              }

              &:before, &:after {
                content: "";
                border-radius: 50%;
                position: absolute;
                right: 0;
                background-color: #fff;
                width: $px24;
                height: $px24;
              }

              &:before {
                top: 0;
                transform: translate(50%, -50%);
                border: $px1 solid #f2f2f2;
              }

              &:after {
                bottom: 0;
                transform: translate(50%, 50%);
                border: $px1 solid #f2f2f2;
              }
            }

            .right {
              border: $px1 solid #f2f2f2;
              border-left: 0;
              border-radius: 0 $px24 $px24 0;
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              gap: $px16;
              padding: $px20;

              .badges {
                .badge {
                  background-color: $colorBackground;
                  border-radius: $px12;
                  padding: $px10 $px14;

                  &:not(:last-child) {
                    margin-right: $px8;
                  }
                }
              }
            }

            &:not(:last-child) {
              margin-bottom: $px10;
            }
          }
        }
      }

      &.corp {
        .child .content {
          .subject {
            font-weight: 500;
          }

          > div > .title {
            display: flex;
            align-items: center;
            gap: $px4;
            margin-bottom: $px16;

            > .img {
              width: $px16;
              height: $px16;
            }
          }

          .history {
            ul {
              font-size: $px14;
              font-weight: 400;

              li {
                line-height: 1.2;
                padding-left: $px24;
                position: relative;

                > div {
                  b {
                    font-weight: 500;
                  }
                }

                &:before {
                  content: "";
                  background-color: #fff;
                  border: $px2 solid $colorPoint;
                  border-radius: 50%;
                  width: $px8;
                  height: $px8;
                  position: absolute;
                  top: $px4;
                  left: 0;
                }

                &:after {
                  content: "";
                  position: absolute;
                  top: $px22;
                  left: $px4;
                  height: calc(100% - $px12);
                  width: $px1;
                  border-left: $px1 dashed $colorPoint;
                  transform: translateX(-50%);
                }

                &:first-child, &:last-child {
                  &:before {
                    background-color: $colorPoint;
                  }
                }

                &:last-child {
                  &:after {
                    content: initial;
                  }
                }

                &:not(:last-child) {
                  margin-bottom: $px16;
                }
              }
            }
          }

          .news {
            margin-top: $px48;

            .list {
              display: flex;
              flex-direction: column;
              gap: $px10;

              > a {
                border-radius: $px16;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: $px16;
                padding: $px16 $px20;

                > .subject {
                  b {
                    font-size: $px15;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }

      &.file {
        .parent {
          > button .left {
            display: flex;
            align-items: center;
            gap: $px16;

            .btn {
              border-radius: $px12;

              > .img {
                width: $px16;
                height: $px16;
              }
            }
          }
        }

        .child .content {
          font-weight: 400;

          > .title {
            > .img {
              width: $px16;
              height: $px16;
            }
          }

          ul {
            li {
              margin-top: $px8;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .actions {
                display: flex;
                gap: $px8;

                > .btn {
                  width: $px40;
                  height: $px40;
                  border-radius: $px12;
                  border: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0;

                  > .img {
                    width: $px16;
                    height: $px16;
                  }
                }
              }
            }
          }
        }
      }

      &.warning {
        .child .content {
          .summary {
            background: $colorBackground;
            border-radius: $px20;
            padding: $px20;
            margin-bottom: $px16;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: $px25;
      }
    }
  }

  .ctrl {
    margin-top: $px16;

    > .wrapper {
      max-height: calc(100vh - ($px20));

      > .project-detail-join-btn {
        margin-bottom: $px20;
      }

      > .profile {
        margin-bottom: $px20;
      }

      ul {
        li {
          .btn {
            background-color: #fff;
            border: $px1 solid #f2f2f2;
            border-radius: $px12;
            text-align: left;
            padding: $px10 $px15;
            width: 100%;
            z-index: 1;

            &:hover {
              background-color: #eee;
            }
          }

          &.active {
            button {
              span {
                font-weight: 500;
              }
            }
          }

          &:not(:last-child) {
            margin-bottom: $px10;
          }
        }
      }
    }
  }

  img {
    width: auto;
    height: auto;
  }

  @media(max-width: 991px) {
    .row > .article .part {
      &.income-deduction .child .content {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  @media(max-width: 767px) {
    > .row > .article .part {
      > .wrapper > .title {
        font-size: $px16;
        margin-bottom: $px15;
      }

      &.condition .child .content {
        .main {
          ul li {
            min-width: $px130;
          }
        }

        .sides {
          flex-direction: column;

          .side {
            width: 100%;
            margin: 0;

            ul li {
              > span {
                width: $px170;
              }

              > b {
                vertical-align: top;
                padding-left: $px8;
              }
            }
          }
        }
      }

      &.base .child .content {
        ul li {
          &:not(:last-child) {
            margin-bottom: $px16;
          }
        }
      }

      &.reward .child .content {
        ul li {
          .left {
            flex-basis: 30%;
          }
        }
      }
    }
  }

  &.fixed {
    .ctrl {
      > .wrapper {
        position: sticky;
        right: 0;
        top: $px20;
        display: flex;
        flex-direction: column;

        .project-detail-join-btn {
          flex-shrink: 0;
        }

        > .profile {
          box-shadow: none !important;
          border: $px1 solid #f2f2f2;
          flex-shrink: 0;
        }

        > ul {
          overflow: auto;
          padding-bottom: $px10;
        }
      }
    }
  }
}
</style>